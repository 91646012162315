import { call, put, takeEvery } from "redux-saga/effects";
import { actionTypes as campaignActionTypes } from "./reducer";
import { actionTypes as authActionTypes } from "../auth/reducer";
import {
  getCampaigns as getAllCampaigns,
  getSurveys as getAllSurveys
} from "./service";
import { message } from "antd";

function* getCampaigns(action) {
  const { filters } = action;

  try {
    const campaigns = yield call(getAllCampaigns, filters);
    const surveys = yield call(getAllSurveys, filters);

    yield put({
      type: campaignActionTypes.GET_CAMPAIGNS_REQUEST_SUCCESS,
      campaigns,
      surveys
    });
  } catch (err) {
    const error = err.error_code;

    switch (error) {
      case 2201:
        // yield put({
        //   type: authActionTypes.AUTH_LOGOUT,
        //   error
        // });
        break;
      default:
        yield put({
          type: campaignActionTypes.GET_CAMPAIGNS_REQUEST_FAILED,
          error
        });

        message.error("Failed to get campaigns");
    }
  }
}

function* watchGetCampaignRequest() {
  yield takeEvery(campaignActionTypes.GET_CAMPAIGNS_REQUEST, getCampaigns);
}
export default [watchGetCampaignRequest()];
