import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import auth from "../modules/auth/reducer";
import campaign from "../modules/campaign/reducer";
import post from "../modules/post/reducer";
import profile from "../modules/profile/reducer";
import referral from "../modules/referral/reducer";
import locale from "../common/locale/reducer";
import references from "../common/references/reducer";
import payment from "../modules/payment/reducer";

const authPersistConfig = {
  key: "pstar_auth",
  storage,
  blacklist: ["registrationSuccess", "activateSuccess", "isLoading"]
};

const localePersistConfig = {
  key: "pstar_locale",
  storage
};

export default combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  campaign,
  post,
  profile,
  referral,
  locale: persistReducer(localePersistConfig, locale),
  references,
  payment
});
