import API from "../../utils/api";

export const getInterests = async () => {
  try {
    const response = await API.get("api/v1/interests/");
    const json = await response.json();

    if (!response.ok) {
      throw response;
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const getCountries = async () => {
  try {
    const response = await API.get("api/v1/places/");
    const json = await response.json();

    if (!response.ok) {
      throw response;
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const getRateCards = async currency => {
  try {
    const response = await API.get(
      `api/v1/influencer_campaigns/rates/?currency=${currency}`
    );

    const json = await response.json();

    if (!response.ok) {
      throw response;
    }

    return json;
  } catch (err) {
    throw err;
  }
};
