// Action Types
export const actionTypes = {
  GET_REFERRAL_DETAILS: "GET_REFERRAL_DETAILS",
  GET_REFERRAL_DETAILS_SUCCESS: "GET_REFERRAL_DETAILS_SUCCESS",
  GET_REFERRAL_DETAILS_FAILED: "GET_REFERRAL_DETAILS_FAILED",

  SUBMIT_REFERRAL: "SUBMIT_REFERRAL",
  SUBMIT_REFERRAL_SUCCESS: "SUBMIT_REFERRAL_SUCCESS",
  SUBMIT_REFERRAL_FAILED: "SUBMIT_REFERRAL_FAILED"
};

// Reducer
const initialState = {
  referral: null,
  isLoading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REFERRAL_DETAILS:
      return Object.assign({}, state, { isLoading: true, error: null });

    case actionTypes.GET_REFERRAL_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        referral: action.payload
      });

    case actionTypes.GET_REFERRAL_DETAILS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        referral: null
      });

    default:
      return state;
  }
};

export const actionCreators = {
  getReferralDetails: () => ({
    type: actionTypes.GET_REFERRAL_DETAILS
  })
};
