import { call, put, takeEvery } from "redux-saga/effects";
import { actionTypes as authActionTypes } from "./reducer";
import { actionTypes as profileActionTypes } from "../profile/reducer";
import {
  login as loginService,
  refreshTokenService,
  registerByEmail,
  activateAccount,
  forgotPassword,
  changePassword
} from "./service";
import { whoami } from "../profile/service";
import { errors as errorsList } from "../../utils/errors";

function* login(action) {
  const { payload } = action;

  try {
    const auth = yield call(loginService, payload);
    yield put({ type: authActionTypes.LOGIN_SUCCESS, auth });

    const profile = yield call(whoami);
    yield put({
      type: profileActionTypes.GET_PROFILE_REQUEST_SUCCESS,
      profile
    });

    // Complete the login process
    yield put({ type: authActionTypes.LOGIN_COMPLETE });
  } catch (err) {
    let errors = errorsList[err.error_code];

    yield put({ type: authActionTypes.LOGIN_FAILED, errors });
  }
}

function* refreshToken(action) {
  const { payload } = action;

  try {
    const auth = yield call(refreshTokenService, payload);
    yield put({ type: authActionTypes.LOGIN_SUCCESS, auth });

    const profile = yield call(whoami);
    yield put({
      type: profileActionTypes.GET_PROFILE_REQUEST_SUCCESS,
      profile
    });
  } catch (err) {
    let errors = errorsList[err.error_code];

    yield put({ type: authActionTypes.LOGIN_FAILED, errors });
    yield put({ type: authActionTypes.AUTH_LOGOUT, errors });
  }
}

function* register(action) {
  const { payload } = action;

  try {
    const user = yield call(registerByEmail, payload);
    yield put({ type: authActionTypes.REGISTER_REQUEST_SUCCESS, user });
  } catch (err) {
    yield put({
      type: authActionTypes.REGISTER_REQUEST_FAILED,
      errors: err.error_code
    });
  }
}

function* activate(action) {
  const { payload } = action;

  try {
    const activate = yield call(activateAccount, payload);
    yield put({ type: authActionTypes.ACTIVATE_REQUEST_SUCCESS, activate });
  } catch (err) {
    const errors = err.message || err;
    yield put({ type: authActionTypes.ACTIVATE_REQUEST_FAILED, errors });
  }
}

function* forgotPasswordRequest(action) {
  const { payload } = action;

  try {
    const forgot = yield call(forgotPassword, payload);
    yield put({
      type: authActionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS,
      forgot
    });
  } catch (err) {
    let errors = errorsList[err.error_code];

    yield put({ type: authActionTypes.FORGOT_PASSWORD_REQUEST_FAILED, errors });
  }
}

function* changePasswordRequest(action) {
  const { payload } = action;

  try {
    const change = yield call(changePassword, payload);
    yield put({
      type: authActionTypes.CHANGE_PASSWORD_REQUEST_SUCCESS,
      change
    });
  } catch (err) {
    const errors = err.message || err;
    yield put({ type: authActionTypes.CHANGE_PASSWORD_REQUEST_FAILED, errors });
  }
}

function* watchLoginRequest() {
  yield takeEvery(authActionTypes.LOGIN_REQUEST, login);
}

function* watchRefreshTokenRequest() {
  yield takeEvery(authActionTypes.AUTH_REFRESH_TOKEN, refreshToken);
}

function* watchRegister() {
  yield takeEvery(authActionTypes.REGISTER_REQUEST, register);
}

function* watchActivate() {
  yield takeEvery(authActionTypes.ACTIVATE_REQUEST, activate);
}

function* watchForgotPasswordRequest() {
  yield takeEvery(
    authActionTypes.FORGOT_PASSWORD_REQUEST,
    forgotPasswordRequest
  );
}

function* watchChangePasswordRequest() {
  yield takeEvery(
    authActionTypes.CHANGE_PASSWORD_REQUEST,
    changePasswordRequest
  );
}

export default [
  watchLoginRequest(),
  watchRefreshTokenRequest(),
  watchRegister(),
  watchActivate(),
  watchForgotPasswordRequest(),
  watchChangePasswordRequest()
];
