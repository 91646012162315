import { setToken, backToLoginPage, deleteToken } from "../../utils/token";
import moment from "moment";

// Action Types
export const actionTypes = {
  AUTH_CHECK: "AUTH_CHECK",
  AUTH_LOGIN: "AUTH_LOGIN",
  AUTH_LOGOUT: "AUTH_LOGOUT",
  AUTH_REFRESH_TOKEN: "AUTH_REFRESH_TOKEN",
  AUTH_RESET_PASSWORD: "AUTH_RESET_PASSWORD",
  AUTH_USER: "AUTH_USER",
  SESSION_TIMEOUT: "SESSION_TIMEOUT",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOGIN_COMPLETE: "LOGIN_COMPLETE",

  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_REQUEST_SUCCESS: "REGISTER_REQUEST_SUCCESS",
  REGISTER_REQUEST_FAILED: "REGISTER_REQUEST_FAILED",

  ACTIVATE_REQUEST: "ACTIVATE_REQUEST",
  ACTIVATE_REQUEST_SUCCESS: "ACTIVATE_REQUEST_SUCCESS",
  ACTIVATE_REQUEST_FAILED: "ACTIVATE_REQUEST_FAILED",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_REQUEST_SUCCESS: "FORGOT_PASSWORD_REQUEST_SUCCESS",
  FORGOT_PASSWORD_REQUEST_FAILED: "FORGOT_PASSWORD_REQUEST_FAILED",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_REQUEST_SUCCESS: "CHANGE_PASSWORD_REQUEST_SUCCESS",
  CHANGE_PASSWORD_REQUEST_FAILED: "CHANGE_PASSWORD_REQUEST_FAILED"
};

// Action creators
export const actionCreators = {
  login: payload => ({ type: actionTypes.LOGIN_REQUEST, payload }),
  refreshTokenService: payload => ({
    type: actionTypes.AUTH_REFRESH_TOKEN,
    payload
  }),
  registerByEmail: payload => ({ type: actionTypes.REGISTER_REQUEST, payload }),
  activateAccount: payload => ({ type: actionTypes.ACTIVATE_REQUEST, payload }),
  forgotPasswordRequest: payload => ({
    type: actionTypes.FORGOT_PASSWORD_REQUEST,
    payload
  }),
  changePasswordRequest: payload => ({
    type: actionTypes.CHANGE_PASSWORD_REQUEST,
    payload
  })
};

// Reducer
const initialState = {
  isAuthenticated: false,
  isLoading: false,
  accessToken: null,
  refreshToken: null,
  expiration: null,
  errors: null,
  registrationSuccess: false,
  activateSuccess: false,
  forgotPasswordSuccess: false,
  passwordChangeSuccess: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_TIMEOUT:
      return Object.assign({}, state, {
        expiration: moment().unix()
      });

    case actionTypes.AUTH_REFRESH_TOKEN:
    case actionTypes.CHANGE_PASSWORD_REQUEST:
    case actionTypes.FORGOT_PASSWORD_REQUEST:
    case actionTypes.ACTIVATE_REQUEST:
    case actionTypes.REGISTER_REQUEST:
    case actionTypes.LOGIN_REQUEST:
      deleteToken();

      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        registrationSuccess: false,
        activateSuccess: false,
        forgotPasswordSuccess: false,
        passwordChangeSuccess: false
      });

    case actionTypes.LOGIN_SUCCESS:
      setToken(
        action.auth.access_token,
        action.auth.refresh_token,
        action.auth.expires_in + moment().unix()
      );

      return Object.assign({}, state, {
        errors: null,
        accessToken: action.auth.access_token,
        refreshToken: action.auth.refresh_token,
        expiration: action.auth.expires_in + moment().unix()
      });

    case actionTypes.LOGIN_COMPLETE:
      return Object.assign({}, state, {
        // isLoading: false,
        errors: null,
        isAuthenticated: true
      });

    case actionTypes.REGISTER_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        registrationSuccess: true
      });

    case actionTypes.REGISTER_REQUEST_FAILED:
    case actionTypes.LOGIN_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.errors,
        isAuthenticated: false,
        accessToken: null,
        registrationSuccess: false
      });

    case actionTypes.ACTIVATE_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        activateSuccess: true
      });

    case actionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        forgotPasswordSuccess: true
      });

    case actionTypes.CHANGE_PASSWORD_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        passwordChangeSuccess: true
      });

    case actionTypes.FORGOT_PASSWORD_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.errors,
        forgotPasswordSuccess: false
      });

    case actionTypes.CHANGE_PASSWORD_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.errors,
        passwordChangeSuccess: false
      });

    case actionTypes.ACTIVATE_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        activateSuccess: false
      });

    case actionTypes.AUTH_LOGOUT:
      backToLoginPage();

      return Object.assign({}, state, {
        isAuthenticated: false,
        isLoading: false,
        accessToken: null,
        errors: null,
        registrationSuccess: false,
        activateSuccess: false,
        forgotPasswordSuccess: false,
        passwordChangeSuccess: false
      });

    default:
      return state;
  }
};
