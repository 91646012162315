import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Routes from "./routes";
import store from "./store";
import ReactGA from "react-ga";
import "unfetch/polyfill";
import "autotrack";
import "url-search-params-polyfill";
import {
  setToken,
  getToken,
  getRefreshToken,
  deleteToken
} from "./utils/token";
import { refreshTokenService } from "./modules/auth/service";

import ErrorBoundary from "./common/ErrorBoundary";

import "antd/dist/antd.css";
// import "video-react/dist/video-react.css";
import "./index.css";
import "./video.css";
import { unregister } from "./registerServiceWorker";

import { PersistGate } from "redux-persist/integration/react";

import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable } from "apollo-link";
import { setContext } from "apollo-link-context";

// attach authorization header every request to graph
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );

        if (graphQLErrors[0].message === "Authentication failure") {
          const refreshToken = getRefreshToken();
          deleteToken();

          return new Observable(observer => {
            refreshTokenService({ refresh_token: refreshToken })
              .then(newToken => {
                console.log("New token acquired.");
                setToken(
                  newToken.access_token,
                  newToken.refresh_token,
                  newToken.expires_in
                );

                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    // Re-add old headers
                    ...headers,
                    // Switch out old access token for new one
                    authorization: `Bearer ${newToken.access_token}` || null
                  }
                }));
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                };

                // Retry last failed request
                forward(operation).subscribe(subscriber);
              })
              .catch(error => {
                // No refresh token or invalid
                window.localStorage.clear();
                window.location.href = "/";
                observer.error(error);
              });
          });
        }
      }

      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    authLink,
    new HttpLink({
      uri: `${process.env.REACT_APP_API_URL}graph/`,
      credentials: "same-origin"
    })
  ]),
  cache: new InMemoryCache(),
  uri: `${process.env.REACT_APP_API_URL}graph/`,
  request: operation => {
    const token = getToken();
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ""
      }
    });
  }
});

// const client = new ApolloClient({
//   uri: `${process.env.REACT_APP_API_URL}graph/`,
//   request: operation => {
//     const token = getToken();
//     operation.setContext({
//       headers: {
//         authorization: token ? `Bearer ${token}` : ""
//       }
//     });
//   }
// });

ReactGA.initialize("UA-123150541-1");
ReactGA.ga("require", "eventTracker");

if (!global.Intl) {
  import("intl").then(intl => {
    ReactDOM.render(
      <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
          <ErrorBoundary>
            <ApolloProvider client={client}>
              <Routes />
            </ApolloProvider>
          </ErrorBoundary>
        </PersistGate>
      </Provider>,
      document.getElementById("root")
    );
  });
} else {
  ReactDOM.render(
    <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <ErrorBoundary>
          <ApolloProvider client={client}>
            <Routes />
          </ApolloProvider>
        </ErrorBoundary>
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
}

unregister();
