// import { setToken, getToken, deleteToken } from '../../utils/token'

// Action Types
export const actionTypes = {
  GET_CAMPAIGNS_REQUEST: "GET_CAMPAIGNS_REQUEST",
  GET_CAMPAIGNS_REQUEST_SUCCESS: "GET_CAMPAIGNS_REQUEST_SUCCESS",
  GET_CAMPAIGNS_REQUEST_FAILED: "GET_CAMPAIGNS_REQUEST_FAILED"
};

// Reducer
const initialState = {
  campaigns: null,
  surveys: null,
  isLoading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CAMPAIGNS_REQUEST:
      return Object.assign({}, state, { isLoading: true, error: null });

    case actionTypes.GET_CAMPAIGNS_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        campaigns: action.campaigns,
        surveys: Object.assign({}, action.surveys, {
          surveys: action.surveys.surveys.filter(survey => {
            if (survey.status === "05" && survey.enqPerPanelStatus === "05") {
              return true;
            }

            return false;
          })
        })
      });

    case actionTypes.GET_CAMPAIGNS_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        campaigns: null,
        surveys: null
      });

    default:
      return state;
  }
};

export const actionCreators = {
  getCampaignsRequest: filters => ({
    type: actionTypes.GET_CAMPAIGNS_REQUEST,
    filters
  })
};
