import API from "../../utils/api";
import { formatError } from "../../utils/errorFormat";

export const whoami = async () => {
  try {
    const response = await API.get(`api/v1/users/me/`);

    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const updateProfile = async payload => {
  try {
    if (!payload.country) {
      const geoip = await fetch("https://ipapi.co/json/");
      const geoipResponse = await geoip.json();

      payload = {
        ...payload,
        country: geoipResponse.country_name
      };
    }
    const response = await API.put(`api/v1/users/${payload.id}/`, payload);

    const json = await response.json();

    if (!response.ok) {
      throw new formatError(json);
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const updateInterests = async payload => {
  try {
    payload = {
      ...payload
    };

    const response = await API.put(
      `api/v1/users/${payload.id}/interests/`,
      payload
    );

    const json = await response.json();

    if (!response.ok) {
      throw new formatError(json);
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const onboardingRequest = async payload => {
  try {
    payload = {
      ...payload
    };

    const response = await API.put(
      `api/v1/users/${payload.id}/onboarding/`,
      payload
    );

    const json = await response.json();

    if (!response.ok) {
      throw new formatError(json);
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const getUserChannel = async () => {
  try {
    const response = await API.get(`api/v1/user_channels/`);

    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const getConnectedBusiness = async () => {
  try {
    const response = await API.get(`api/v1/facebook_pages/`);

    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const createUserChannel = async payload => {
  try {
    const response = await API.post(`api/v1/user_channels/`, payload);

    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};
