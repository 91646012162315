import API from "../../utils/api";

export const getReferralDetails = async () => {
  try {
    const response = await API.get(`api/v1/users/referral/details/`);

    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const submitReferral = async payload => {
  try {
    const response = await API.post(`api/v1/users/referral/`, payload);

    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};
