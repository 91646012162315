import API from '../../utils/api';

export const getPosts = async (filters) => {
    try {
        const response = await API.get('api/v1/posts/');

        const json = await response.json();

        if (! response.ok) {
            throw json;
        }

        return json;
    } catch (err) {
        throw err;
    }
};

export const uploadImage = async (payload) => {
    try {
        const response = await API.post(`api/v1/upload/`, payload);

        const json = await response.json();

        if (! response.ok) {
            throw json;
        }

        return json;
    } catch (err) {
        throw err;
    }
}

export const createPost = async (campaignId, post) => {
    try {
        const response = await API.post(`api/v1/influencer_campaigns/${campaignId}/posts/`, post);

        const json = await response.json();

        if (! response.ok) {
            throw json;
        }

        return json;
    } catch (err) {
        throw err;
    }
};

export const changePostStatus = async (postId, post) => {
    try {
        const response = await API.patch(`api/v1/posts/${postId}/`, post);

        const json = await response.json();

        if (! response.ok) {
            throw json;
        }

        return json;
    } catch (err) {
        throw err;
    }
};