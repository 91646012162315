// import { setToken, getToken, deleteToken } from '../../utils/token'

// Action Types
export const actionTypes = {
  GET_PROFILE_REQUEST: "GET_PROFILE_REQUEST",
  GET_PROFILE_REQUEST_SUCCESS: "GET_PROFILE_REQUEST_SUCCESS",
  GET_PROFILE_REQUEST_FAILED: "GET_PROFILE_REQUEST_FAILED",
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_REQUEST_SUCCESS: "UPDATE_PROFILE_REQUEST_SUCCESS",
  UPDATE_PROFILE_REQUEST_FAILED: "UPDATE_PROFILE_REQUEST_FAILED",
  UPDATE_INTERESTS_REQUEST: "UPDATE_INTERESTS_REQUEST",
  UPDATE_INTERESTS_REQUEST_SUCCESS: "UPDATE_INTERESTS_REQUEST_SUCCESS",
  UPDATE_INTERESTS_REQUEST_FAILED: "UPDATE_INTERESTS_REQUEST_FAILED",

  CONNECT_SOCIAL_MEDIA_REQUEST: "CONNECT_SOCIAL_MEDIA_REQUEST",
  CONNECT_SOCIAL_MEDIA_REQUEST_SUCCESS: "CONNECT_SOCIAL_MEDIA_REQUEST_SUCCESS",
  CONNECT_SOCIAL_MEDIA_REQUEST_FAILED: "CONNECT_SOCIAL_MEDIA_REQUEST_FAILED",

  ONBOARDING_REQUEST: "ONBOARDING_REQUEST",
  ONBOARDING_REQUEST_SUCCESS: "ONBOARDING_REQUEST_SUCCESS",
  ONBOARDING_REQUEST_FAILED: "ONBOARDING_REQUEST_FAILED",

  GET_CONNECTED_BUSINESS_REQUEST: "GET_CONNECTED_BUSINESS_REQUEST",
  GET_CONNECTED_BUSINESS_SUCCESS: "GET_CONNECTED_BUSINESS_SUCCESS",
  GET_CONNECTED_BUSINESS_FAILED: "GET_CONNECTED_BUSINESS_FAILED",

  GET_USER_CHANNELS_REQUEST: "GET_USER_CHANNELS_REQUEST",
  GET_USER_CHANNELS_SUCCESS: "GET_USER_CHANNELS_SUCCESS",
  GET_USER_CHANNELS_FAILED: "GET_USER_CHANNELS_FAILED",

  CREATE_USER_CHANNEL_REQUEST: "CREATE_USER_CHANNEL_REQUEST",
  CREATE_USER_CHANNEL_SUCCESS: "CREATE_USER_CHANNEL_SUCCESS",
  CREATE_USER_CHANNEL_FAILED: "CREATE_USER_CHANNEL_FAILED"
};

// Reducer
const initialState = {
  profile: null,
  referral: null,
  isLoading: false,
  isLoadingBusiness: false,
  errors: null,
  userChannel: [],
  connectedBusiness: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ONBOARDING_REQUEST:
    case actionTypes.CONNECT_SOCIAL_MEDIA_REQUEST:
    case actionTypes.UPDATE_INTERESTS_REQUEST:
    case actionTypes.GET_PROFILE_REQUEST:
    case actionTypes.GET_USER_CHANNELS_REQUEST:
    case actionTypes.UPDATE_PROFILE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        updated: false
      });

    case actionTypes.CREATE_USER_CHANNEL_REQUEST:
    case actionTypes.GET_CONNECTED_BUSINESS_REQUEST:
      return Object.assign({}, state, {
        isLoadingBusiness: true,
        errors: null
      });

    case actionTypes.GET_PROFILE_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        profile: action.profile
      });

    case actionTypes.GET_PROFILE_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.errors,
        profile: null
      });

    case actionTypes.GET_USER_CHANNELS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        userChannel: action.userChannel
      });

    case actionTypes.GET_CONNECTED_BUSINESS_SUCCESS:
      return Object.assign({}, state, {
        isLoadingBusiness: false,
        errors: null,
        connectedBusiness: action.connectedBusiness
      });

    case actionTypes.CREATE_USER_CHANNEL_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isLoadingBusiness: false,
        errors: null,
        userChannel: state.userChannel.concat([action.userChannel])
      });

    case actionTypes.CONNECT_SOCIAL_MEDIA_REQUEST_FAILED:
    case actionTypes.UPDATE_INTERESTS_REQUEST_FAILED:
    case actionTypes.UPDATE_PROFILE_REQUEST_FAILED:
    case actionTypes.ONBOARDING_REQUEST_FAILED:
    case actionTypes.GET_CONNECTED_BUSINESS_FAILED:
    case actionTypes.CREATE_USER_CHANNEL_FAILED:
    case actionTypes.GET_USER_CHANNELS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isLoadingBusiness: false,
        errors: action.errors
      });

    case actionTypes.UPDATE_PROFILE_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        profile: action.updatedProfile,
        updated: true
      });

    case actionTypes.CONNECT_SOCIAL_MEDIA_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null
      });

    case actionTypes.UPDATE_INTERESTS_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        profile: action.profile
      });

    case actionTypes.ONBOARDING_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        profile: action.profile,
        referral: action.referral
      });

    default:
      return state;
  }
};

export const actionCreators = {
  getProfileRequest: () => ({ type: actionTypes.GET_PROFILE_REQUEST }),
  getUserChannelRequest: () => ({
    type: actionTypes.GET_USER_CHANNELS_REQUEST
  }),
  createUserChannel: payload => ({
    type: actionTypes.CREATE_USER_CHANNEL_REQUEST,
    payload
  }),
  getConnectedBusinessRequest: () => ({
    type: actionTypes.GET_CONNECTED_BUSINESS_REQUEST
  }),
  updateProfileRequest: payload => ({
    type: actionTypes.UPDATE_PROFILE_REQUEST,
    payload
  }),
  updateInterestsRequest: payload => ({
    type: actionTypes.UPDATE_INTERESTS_REQUEST,
    payload
  }),
  onBoardingRequest: payload => ({
    type: actionTypes.ONBOARDING_REQUEST,
    payload
  }),
  connectSocialMedia: payload => ({
    type: actionTypes.CONNECT_SOCIAL_MEDIA_REQUEST,
    payload
  })
};
