// import { purgeStoredState } from "redux-persist";
// import storage from "redux-persist/lib/storage";

export const setToken = (accessToken, refreshToken, expiration) => {
  window.localStorage.setItem("popstar_accessToken", accessToken);
  window.localStorage.setItem("popstar_refreshToken", refreshToken);
  window.localStorage.setItem("popstar_expiration", expiration);

  return accessToken;
};

export const getToken = () => {
  return window.localStorage.getItem("popstar_accessToken");
};

export const getRefreshToken = () => {
  return window.localStorage.getItem("popstar_refreshToken");
};

export const getExpiration = () => {
  return window.localStorage.getItem("popstar_expiration");
};

export const deleteToken = () => {
  window.localStorage.removeItem("popstar_refreshToken");
  window.localStorage.removeItem("popstar_expiration");
  return window.localStorage.removeItem("popstar_accessToken");
};

export const backToLoginPage = () => {
  // const persistConfig = {
  //   key: "pstar",
  //   storage
  // };
  // const authPersistConfig = {
  //   key: "pstar_auth",
  //   storage
  // };
  // deleteToken();
  // purgeStoredState(persistConfig);
  // purgeStoredState(authPersistConfig);

  // Clear all localStorage
  window.localStorage.clear();
};
