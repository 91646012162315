import Loadable from 'react-loadable'
import LoadingComponent from '../../common/loader'

export default [
    {
        path: '/dashboard/posts',
        exact: true,
        authL: true,
        component: Loadable({
            loader: () => import('./pages/list'),
            loading: LoadingComponent,
        }),
    }
]