import { call, put, takeEvery, all, takeLatest } from "redux-saga/effects";
import { actionTypes as referencesActionTypes } from "./reducer";
import {
  getInterests,
  getCountries,
  getRateCards as getRateCardsService
} from "./service";

function* getReferences(action) {
  try {
    const [interests, countries] = yield all([
      call(getInterests),
      call(getCountries)
    ]);

    yield put({ type: referencesActionTypes.GET_INTERESTS_SUCCESS, interests });
    yield put({ type: referencesActionTypes.GET_COUNTRIES_SUCCESS, countries });

    yield put({ type: referencesActionTypes.REFERENCES_LOADED });
  } catch (err) {
    console.log(err);
    yield put({ type: referencesActionTypes.REFERENCES_LOAD_FAILED });
  }
}

function* getRateCards(action) {
  try {
    const { currency } = action;
    const rateCards = yield call(getRateCardsService, currency);

    yield put({
      type: referencesActionTypes.GET_RATE_CARDS_SUCCESS,
      rateCards
    });
  } catch (err) {
    yield put({ type: referencesActionTypes.REFERENCES_LOAD_FAILED });
  }
}

function* watchGetReferences() {
  yield takeLatest(referencesActionTypes.GET_REFERENCES, getReferences);
}

function* watchGetRateCards() {
  yield takeEvery(referencesActionTypes.GET_RATE_CARDS, getRateCards);
}

export default [watchGetReferences(), watchGetRateCards()];
