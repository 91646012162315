import { call, put, takeEvery } from "redux-saga/effects";
import { actionTypes as referralActionTypes } from "./reducer";
import { actionTypes as authActionTypes } from "../auth/reducer";
import {
  getReferralDetails as getReferralDetailsService
  // submitReferral as submitReferralService
} from "./service";
import { message } from "antd";

function* getReferralDetails(action) {
  try {
    const payload = yield call(getReferralDetailsService);

    yield put({
      type: referralActionTypes.GET_REFERRAL_DETAILS_SUCCESS,
      payload
    });
  } catch (err) {
    const error = err.error_code;

    switch (error) {
      case 2201:
        // yield put({
        //   type: authActionTypes.AUTH_LOGOUT,
        //   error
        // });
        break;
      default:
        yield put({
          type: referralActionTypes.GET_REFERRAL_DETAILS_FAILED,
          error
        });

        message.error("Failed to get referral details");
    }
  }
}

// function* submitReferral(action) {
//   const { params } = action;

//   try {
//     const profile = yield call(submitReferralService, params);

//     yield put({
//       type: profileActionTypes.SUBMIT_REFERRAL_SUCCESS,
//       profile
//     });
//   } catch (err) {
//     const errors = err.message || err;

//     yield put({
//       type: profileActionTypes.SUBMIT_REFERRAL_FAILED,
//       errors
//     });
//   }
// }

function* watchGetReferralDetails() {
  yield takeEvery(referralActionTypes.GET_REFERRAL_DETAILS, getReferralDetails);
}

// function* watchSubmitReferral() {
//   yield takeEvery(referralActionTypes.SUBMIT_REFERRAL, submitReferral);
// }
export default [watchGetReferralDetails()];
