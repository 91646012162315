import API from "../../utils/api";
// import { formatError } from '../../utils/errorFormat';

export const getCampaigns = async filters => {
  try {
    const response = await API.get("api/v1/influencer_campaigns/");

    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const getSurveys = async filters => {
  try {
    const response = await API.get("api/v1/users/surveys/");

    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};
