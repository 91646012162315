import { all } from "redux-saga/effects";
import authSagas from "../modules/auth/saga";
import campaignSagas from "../modules/campaign/saga";
import postSagas from "../modules/post/saga";
import profileSagas from "../modules/profile/saga";
import referralSagas from "../modules/referral/saga";
import referencesSagas from "../common/references/saga";
import paymentsSagas from "../modules/payment/saga";

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...campaignSagas,
    ...postSagas,
    ...profileSagas,
    ...referralSagas,
    ...referencesSagas,
    ...paymentsSagas
  ]);
}
