import { call, put, takeEvery } from "redux-saga/effects";
import { actionTypes as postActionTypes } from "./reducer";
import { actionTypes as authActionTypes } from "../auth/reducer";
import {
  getPosts as getAllPosts,
  createPost as createNewPost,
  uploadImage,
  changePostStatus as updatePostStatus
} from "./service";
import { message } from "antd";

function* getPosts(action) {
  const { filters } = action;

  try {
    const posts = yield call(getAllPosts, filters);

    yield put({ type: postActionTypes.GET_POSTS_REQUEST_SUCCESS, posts });
  } catch (err) {
    const error = err.error_code;

    switch (error) {
      case 2201:
        // yield put({
        //   type: authActionTypes.AUTH_LOGOUT,
        //   error
        // });
        break;
      default:
        yield put({ type: postActionTypes.GET_POSTS_REQUEST_FAILED, error });
        message.error("Failed to get posts");
    }
  }
}

function* uploadMedia(action) {
  try {
    const { imagePayload } = action;

    const uploaded = yield call(uploadImage, imagePayload);

    yield put({ type: postActionTypes.UPLOAD_REQUEST_SUCCESS, uploaded });
    // message.success("Successfully created post");
  } catch (err) {
    const error = err.message || err;
    yield put({ type: postActionTypes.UPLOAD_REQUEST_FAILED, error });
  }
}

function* createPost(action) {
  try {
    const { campaignId, media, post } = action;
    // Create new post payload
    const postPayload = {
      ...post,
      media: media ? media.toString() : ""
    };
    const newPost = yield call(createNewPost, campaignId, postPayload);

    yield put({ type: postActionTypes.CREATE_POST_REQUEST_SUCCESS, newPost });
    message.success("Successfully created post");
  } catch (err) {
    const error = err.message || err;
    yield put({ type: postActionTypes.CREATE_POST_REQUEST_FAILED, error });
    const { post } = action;

    if (error.error_code === 2102) {
      message.error(`You've already created the ${post.platform} post`);
    } else {
      message.error(`Failed to create post`);
    }
  }
}

function* updatePost(action) {
  try {
    const { postId, post } = action;

    const updatedPost = yield call(updatePostStatus, postId, post);

    yield put({
      type: postActionTypes.UPDATE_POST_REQUEST_SUCCESS,
      updatedPost
    });
    message.success("Successfully updated post");
  } catch (err) {
    const error = err.message || err;
    yield put({ type: postActionTypes.UPDATE_POST_REQUEST_FAILED, error });

    message.error("Failed to update post");
  }
}

function* updateRevisePost(action) {
  try {
    const { postId, post, imagePayload } = action;

    // Create new post payload
    const postPayload = {
      ...post,
      media: imagePayload ? imagePayload.toString() : ""
    };

    const updatedPost = yield call(updatePostStatus, postId, postPayload);

    yield put({
      type: postActionTypes.UPDATE_POST_REQUEST_SUCCESS,
      updatedPost
    });
    message.success("Successfully revised post");
  } catch (err) {
    const error = err.message || err;
    yield put({ type: postActionTypes.UPDATE_POST_REQUEST_FAILED, error });

    message.error("Failed to update post");
  }
}

function* watchGetPostRequest() {
  yield takeEvery(postActionTypes.GET_POSTS_REQUEST, getPosts);
}

function* watchCreatePostRequest() {
  yield takeEvery(postActionTypes.CREATE_POST_REQUEST, createPost);
}

function* watchUpdatePostRequest() {
  yield takeEvery(postActionTypes.UPDATE_POST_REQUEST, updatePost);
}

function* watchUpdateRevisePostRequest() {
  yield takeEvery(postActionTypes.UPDATE_REVISE_POST_REQUEST, updateRevisePost);
}

function* watchUploadMedia() {
  yield takeEvery(postActionTypes.UPLOAD_REQUEST, uploadMedia);
}

export default [
  watchGetPostRequest(),
  watchCreatePostRequest(),
  watchUpdatePostRequest(),
  watchUpdateRevisePostRequest(),
  watchUploadMedia()
];
