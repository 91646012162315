import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import LoadingComponent from "./common/skeletonLoader";
import {
  Layout,
  Menu,
  Icon,
  Select,
  Divider,
  Row,
  Col,
  Modal,
  Badge,
} from "antd";
// import { purgeStoredState } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import { backToLoginPage } from "./utils/token";
import { getFingerprint } from "./utils/fingerprint";

import NoticeIcon from "./common/NoticeIcon";
import moment from "moment";
import groupBy from "lodash/groupBy";

import { actionCreators as localeActionCreators } from "./common/locale/reducer";
import { actionCreators as referencesActionCreators } from "./common/references/reducer";
import { actionCreators as profileActionCreators } from "./modules/profile/reducer";
import { actionCreators as authActionCreators } from "./modules/auth/reducer";
import { IntlProvider, addLocaleData, FormattedMessage } from "react-intl";
import intlEN from "react-intl/locale-data/en";
import intlID from "react-intl/locale-data/id";

import * as Sentry from "@sentry/browser";

import "./App.css";

import ReactGA from "react-ga";

addLocaleData([...intlEN, ...intlID]);

const landingUrl = `${process.env.REACT_APP_LANDING_PROTOCOL}://${
  process.env.REACT_APP_LANDING_URL
}`;

const Option = Select.Option;
const { Content, Footer, Sider, Header } = Layout;

const data = [];

class App extends Component {
  state = { logoutVisible: false };

  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    // user: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    // ReactGA.pageview(window.location.pathname + window.location.search);

    if (!this.props.locale) {
      let locale = window.sessionStorage.getItem("locale");
      if (locale) {
        this.props.changeLocale(locale);
        return;
      }

      let lang = navigator.language;

      if (lang.indexOf("en") !== -1) {
        lang = "en";
      }

      this.props.changeLocale(lang);
    }
  }

  changeLanguageSelection = (lang) => {
    this.props.changeLocale(lang);
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  showModal = () => {
    this.setState({
      logoutVisible: true,
    });
  };

  onOk = () => {
    const locale = this.props.locale;

    new Promise((resolve, reject) => {
      backToLoginPage();
      window.sessionStorage.setItem("locale", locale);

      setTimeout(function() {
        resolve();
      }, 100);
    }).then((res) => {
      window.location.href = "/";
    });
  };

  hideModal = () => {
    this.setState({
      logoutVisible: false,
    });
  };

  onItemClick = (item, tabProps) => {
    // console.log(item, tabProps);
  };

  onClear = (tabTitle) => {
    // console.log(tabTitle);
  };

  getNoticeData = (notices) => {
    if (notices.length === 0) {
      return {};
    }
    const newNotices = notices.map((notice) => {
      const newNotice = { ...notice };
      if (newNotice.datetime) {
        newNotice.datetime = moment(notice.datetime).fromNow();
      }
      // transform id to item key
      if (newNotice.id) {
        newNotice.key = newNotice.id;
      }
      return newNotice;
    });
    return groupBy(newNotices, "type");
  };

  checkSession = () => {
    const { expiration, refreshToken, refreshTokenService } = this.props;

    const timestamp = moment().unix();
    const diff = expiration - timestamp;

    // console.log("checking session", diff);

    if (diff < 50) {
      refreshTokenService({ refresh_token: refreshToken });
    }
  };

  trackingAndUserSession = () => {
    const { profile } = this.props;

    // console.log("profile inside render", profile);

    if (profile) {
      ReactGA.ga("set", "userId", profile.id);

      // console.log("interests", this.props.interests);
      if (!this.props.interests) {
        this.props.getReferences();
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.isAuthenticated) {
      this.trackingAndUserSession();
    }

    // User Context logging
    if (this.props.profile) {
      Sentry.configureScope((scope) => {
        scope.setUser({ id: this.props.profile.id });
      });
    }
  }

  async componentDidMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.checkSession();
    }

    // if (this.props.isAuthenticated) {
    //   const { getProfileRequest } = this.props;
    //   getProfileRequest();
    // }

    let search = new URLSearchParams(window.location.search);
    let qs = {};

    // Build querystring object
    for (let p of search) {
      qs = Object.assign({}, qs, {
        [p[0]]: p[1],
      });
      if (qs.psref) {
        document.cookie = `psref=${qs.psref};domain=.pop-star.me`;
      }
    }

    const fg = window.localStorage.getItem("psfg");
    if (!fg) {
      const fingerprint = await getFingerprint();
      const geo = await fetch("https://extreme-ip-lookup.com/json");
      const geo_json = await geo.json();
      const combinedFp = {
        geo: geo_json,
        userAgent: fingerprint.find((item) => item.key === "userAgent"),
        deviceMemory: fingerprint.find((item) => item.key === "deviceMemory"),
        hardwareConcurrency: fingerprint.find(
          (item) => item.key === "hardwareConcurrency"
        ),
        screenResolution: fingerprint.find(
          (item) => item.key === "screenResolution"
        ),
        platform: fingerprint.find((item) => item.key === "platform"),
        audio: fingerprint.find((item) => item.key === "audio"),
      };

      // console.log(combinedFp);
      window.localStorage.setItem("psfg", JSON.stringify(combinedFp));
    }
  }

  renderWithSidebar = (node) => {};

  renderWithoutSidebar = (node) => {
    const { locale, messages } = this.props;

    return (
      <IntlProvider key={locale} locale={locale} messages={messages}>
        <div className="app">
          <main className="main">
            <Layout id="auth">
              <Content>
                <div>{node}</div>
              </Content>
              <Footer>
                <Row>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 11 }}
                    xl={{ span: 12 }}
                  >
                    <p style={{ margin: "10px 0 6px" }}>
                      Copyright 2019 © PopStar. All rights reserved.
                    </p>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 11 }}
                    className="footer-selection"
                  >
                    <a href="mailto:tony@pop-star.me">
                      <FormattedMessage id="contact" />
                    </a>
                    <Divider type="vertical" />
                    <a href={`${landingUrl}privacy`}>
                      <FormattedMessage id="privacy" />
                    </a>
                    <Divider type="vertical" />
                    <a href={`${landingUrl}terms`}>
                      <FormattedMessage id="terms" />
                    </a>

                    <span className="language-selector-text">
                      <FormattedMessage id="select_language" />：
                      <Select
                        value={locale}
                        onChange={this.changeLanguageSelection}
                        dropdownMatchSelectWidth={false}
                        showArrow={false}
                      >
                        <Option value="en">English</Option>
                        <Option value="id">Bahasa Indonesia</Option>
                      </Select>
                    </span>
                  </Col>
                </Row>
              </Footer>
            </Layout>
          </main>
        </div>
      </IntlProvider>
    );
  };

  render() {
    const {
      isAuthenticated,
      locale,
      messages,
      isLoading,
      profile,
    } = this.props;
    const noticeData = this.getNoticeData([]);
    let pathname = window.location.pathname;

    if (isAuthenticated && profile) {
      if (isLoading && !profile) {
        return <LoadingComponent isLoading={isLoading} />;
      }

      if (!profile.email) {
        return this.renderWithoutSidebar(this.props.children);
      }

      return (
        <IntlProvider key={locale} locale={locale} messages={messages}>
          <Layout style={{ minHeight: "100vh" }} className="dashboard">
            <Sider
              breakpoint="lg"
              collapsedWidth="0"
              onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
              }}
            >
              <div className="brand-image">
                <img src="/assets/img/logo.png" alt="PopStar" />
              </div>
              <Menu
                theme="dark"
                defaultSelectedKeys={["/dashboard"]}
                selectedKeys={[window.location.pathname]}
                mode="inline"
              >
                <Menu.Item key="/dashboard">
                  <NavLink to="/dashboard">
                    <Icon type="home" />
                    <span>Get Started</span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="/dashboard/campaigns">
                  <NavLink to="/dashboard/campaigns" className="nav-text">
                    <Icon type="appstore-o" />
                    <span>
                      <FormattedMessage id="campaigns" />
                      <Badge className="pull-right" status="default" />
                    </span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="/dashboard/posts">
                  <NavLink to="/dashboard/posts">
                    <Icon type="form" />
                    <span>
                      <FormattedMessage id="my_posts" />
                    </span>
                  </NavLink>
                </Menu.Item>
                {/* <Menu.Item key="/dashboard/reports">
                  <NavLink to="/dashboard/reports">
                    <Icon type="area-chart" />
                    <span>
                      <FormattedMessage id="reports" />
                    </span>
                  </NavLink>
                </Menu.Item> */}
                <Menu.Item
                  key={
                    pathname === "/dashboard/payments/histories"
                      ? "/dashboard/payments/histories"
                      : "/dashboard/payments/request"
                  }
                >
                  <NavLink to="/dashboard/payments/histories">
                    <Icon type="wallet" />
                    <span>
                      <FormattedMessage id="payment" />
                    </span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="/dashboard/profile">
                  <NavLink to="/dashboard/profile">
                    <Icon type="user" />
                    <span>
                      <FormattedMessage id="my_profile" />
                    </span>
                  </NavLink>
                </Menu.Item>
                {/* <Menu.Item key="/dashboard/channels">
                  <NavLink to="/dashboard/channels">
                    <Icon type="user" />
                    <span>
                      <FormattedMessage
                        defaultMessage="My Channels"
                        id="channels"
                      />
                    </span>
                  </NavLink>
                </Menu.Item> */}
                {/* <Menu.Item key="/dashboard/referrals">
                  <NavLink to="/dashboard/referrals">
                    <Icon type="share-alt" theme="outlined" />
                    <span>
                      <FormattedMessage id="referral" />
                    </span>
                  </NavLink>
                </Menu.Item> */}
              </Menu>
              <div
                className="ant-layout-sider-trigger"
                onClick={this.showModal}
              >
                <div className="menu-logout">
                  <Icon type="logout" />
                  <span>
                    <FormattedMessage id="sign_out" />
                  </span>
                </div>
              </div>
            </Sider>
            <Layout>
              <Header className="notification-header hide">
                <NoticeIcon
                  className="notice-icon"
                  count={data.length}
                  onItemClick={this.onItemClick}
                  onClear={this.onClear}
                  popupAlign={{ offset: [20, -16] }}
                >
                  <NoticeIcon.Tab
                    list={noticeData["Notification"]}
                    title="Notifications"
                    emptyText={<FormattedMessage id="no_notifications" />}
                    emptyImage="/assets/img/notification.svg"
                  />
                </NoticeIcon>
              </Header>
              <Content>
                {React.cloneElement(this.props.children, {
                  profile: this.props.profile,
                  isAuthenticated: this.props.isAuthenticated,
                })}
              </Content>
              <Footer>
                <Row>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                  >
                    Copyright 2018 © PopStar. All rights reserved.
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 11 }}
                    className="footer-selection"
                  >
                    <a href="mailto:tony@pop-star.me">
                      <FormattedMessage id="contact" />
                    </a>
                    <Divider type="vertical" />
                    <a href={`${landingUrl}privacy`}>
                      <FormattedMessage id="privacy" />
                    </a>
                    <Divider type="vertical" />
                    <a href={`${landingUrl}terms`}>
                      <FormattedMessage id="terms" />
                    </a>

                    <span className="language-selector-text">
                      <FormattedMessage id="select_language" />：
                    </span>
                    <Select
                      value={locale}
                      onChange={this.changeLanguageSelection}
                      dropdownMatchSelectWidth={false}
                      showArrow={false}
                    >
                      <Option value="en">English</Option>
                      <Option value="id">Bahasa Indonesia</Option>
                    </Select>
                  </Col>
                </Row>
              </Footer>
            </Layout>
            <Modal
              title={<FormattedMessage id="sign_out" />}
              visible={this.state.logoutVisible}
              onOk={this.onOk}
              onCancel={this.hideModal}
              okText="Ok"
              cancelText="Cancel"
            >
              <p>
                <FormattedMessage id="sign_out_confirmation" />
              </p>
            </Modal>
          </Layout>
        </IntlProvider>
      );
    }

    return this.renderWithoutSidebar(this.props.children);
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(localeActionCreators, dispatch),
  ...bindActionCreators(referencesActionCreators, dispatch),
  ...bindActionCreators(profileActionCreators, dispatch),
  ...bindActionCreators(authActionCreators, dispatch),
});

const mapStateToProps = (state, ownState) => ({
  profile: state.profile.profile,
  isAuthenticated: state.auth.isAuthenticated,
  locale: state.locale.locale,
  messages: state.locale.messages,
  interests: state.references.interests,
  isLoading: state.references.isLoading,
  refreshToken: state.auth.refreshToken,
  expiration: state.auth.expiration,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
