export const formatError = (errors) => {
    let errorKeys = Object.keys(errors);
    let formattedErrors = {};

    for (let key of errorKeys) {
        formattedErrors[key] = {
            errors: errors[key].map(error => ({ message: capitalize(error), field: key }))
        }
    }

    return formattedErrors;
}

const capitalize = (error) => `${error[0].toUpperCase()}${error.slice(1)}`