// Action Types
export const actionTypes = {
  GET_REFERENCES: "GET_REFERENCES",
  GET_RATE_CARDS: "GET_RATE_CARDS",
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  GET_INTERESTS_SUCCESS: "GET_INTERESTS_SUCCESS",
  GET_RATE_CARDS_SUCCESS: "GET_RATE_CARDS_SUCCESS",
  RATE_CARDS_LOAD_FAILED: "RATE_CARDS_LOADED_FAILED",
  REFERENCES_LOAD_FAILED: "REFERENCES_LOADED_FAILED",
  REFERENCES_LOADED: "REFERENCES_LOADED"
};

// Reducer
const initialState = {
  countries: null,
  interests: null,
  rateCards: null,
  isLoading: false
};

// Action creators
export const actionCreators = {
  getReferences: () => ({ type: actionTypes.GET_REFERENCES }),
  getRateCards: currency => ({ type: actionTypes.GET_RATE_CARDS, currency })
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REFERENCES:
      return Object.assign({}, state, {
        isLoading: true,
        countries: null,
        interests: null,
        rateCards: null
      });

    case actionTypes.GET_COUNTRIES_SUCCESS:
      return Object.assign({}, state, {
        countries: action.countries
      });

    case actionTypes.GET_INTERESTS_SUCCESS:
      return Object.assign({}, state, {
        interests: action.interests
      });

    case actionTypes.GET_RATE_CARDS_SUCCESS:
      return Object.assign({}, state, {
        rateCards: action.rateCards
      });

    case actionTypes.REFERENCES_LOAD_FAILED:
    case actionTypes.RATE_CARDS_LOAD_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        countries: null,
        interests: null,
        rateCards: null
      });

    case actionTypes.REFERENCES_LOADED:
      return Object.assign({}, state, {
        isLoading: false
      });

    default:
      return state;
  }
};
