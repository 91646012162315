import { call, put, takeEvery } from "redux-saga/effects";
import { actionTypes as requestActionTypes } from "./reducer";
import {
  getRequest as getRequestService,
  submitRequest as submitRequestService
} from "./service";
import { message } from "antd";
import { errors as errorsList } from "../../utils/errors";

function* getRequest(action) {
  try {
    const payload = yield call(getRequestService);

    yield put({
      type: requestActionTypes.GET_REQUEST_SUCCESS,
      payload
    });
  } catch (err) {
    const error = err.error_code;

    yield put({
      type: requestActionTypes.GET_REQUEST_SUCCESS,
      error
    });

    message.error("Failed to get payment requests");
  }
}

function* submitRequest(action) {
  const { payload } = action;

  try {
    const updatedRequest = yield call(submitRequestService, payload);

    yield put({
      type: requestActionTypes.SUBMIT_REQUEST_SUCCESS,
      updatedRequest
    });
  } catch (err) {
    let errors = errorsList[err.error_code];

    yield put({
      type: requestActionTypes.SUBMIT_REQUEST_FAILED,
      errors
    });
  }
}

function* watchSubmitRequest() {
  yield takeEvery(requestActionTypes.SUBMIT_REQUEST, submitRequest);
}

function* watchGetRequest() {
  yield takeEvery(requestActionTypes.GET_REQUEST, getRequest);
}
export default [watchSubmitRequest(), watchGetRequest()];
