import API from "../../utils/api";

export const login = async (credentials) => {
  try {
    credentials = {
      ...credentials,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    };

    let url = "auth/token/";
    if (credentials.grant_type !== "password") {
      url = "auth/convert-token/";
    }

    console.log(url, "url");
    console.log(credentials);

    const response = await API.post(url, credentials);
    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const refreshTokenService = async (credentials) => {
  try {
    credentials = {
      ...credentials,
      grant_type: "refresh_token",
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    };

    let url = "auth/token/";

    const response = await API.post(url, credentials);
    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const registerByEmail = async (payload) => {
  try {
    const response = await API.post("api/v1/users/", payload);
    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const activateAccount = async (payload) => {
  try {
    const response = await API.post(
      `api/v1/users/${payload.uid}/activate/`,
      payload
    );
    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const forgotPassword = async (payload) => {
  try {
    const response = await API.post(`api/v1/users/forgot_password/`, payload);
    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const changePassword = async (payload) => {
  try {
    const response = await API.post(`update_password/`, payload);
    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};
