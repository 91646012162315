// Action Types
export const actionTypes = {
  GET_REQUEST: "GET_REQUEST",
  GET_REQUEST_SUCCESS: "GET_REQUEST_SUCCESS",
  GET_REQUEST_FAILED: "GET_REQUEST_FAILED",

  SUBMIT_REQUEST: "SUBMIT_REQUEST",
  SUBMIT_REQUEST_SUCCESS: "SUBMIT_REQUEST_SUCCESS",
  SUBMIT_REQUEST_FAILED: "SUBMIT_REQUEST_FAILED"
};

// Reducer
const initialState = {
  paymentRequest: null,
  isLoading: false,
  errors: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBMIT_REQUEST:
    case actionTypes.GET_REQUEST:
      return Object.assign({}, state, { isLoading: true, error: null });

    case actionTypes.GET_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        paymentRequest: action.payload.results
      });

    case actionTypes.SUBMIT_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        paymentRequest: state.paymentRequest.concat([action.updatedRequest])
      });

    case actionTypes.SUBMIT_REQUEST_FAILED:
    case actionTypes.GET_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.errors
      });

    default:
      return state;
  }
};

export const actionCreators = {
  getRequest: () => ({
    type: actionTypes.GET_REQUEST
  }),
  submitRequest: payload => ({
    type: actionTypes.SUBMIT_REQUEST,
    payload
  })
};
