import intlMessagesID from './id.json'
import intlMessagesEN from './en.json'

// Action Types
export const actionTypes = {
    LOCALE_CHANGE: 'LOCALE_CHANGE'
}

// Reducer
const initialState = {
    locale: null,
    messages: intlMessagesEN
}

// Action creators
export const actionCreators = {
    changeLocale: (locale) => ({ type: actionTypes.LOCALE_CHANGE, locale })
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOCALE_CHANGE:
            return Object.assign(
                {},
                state,
                { 
                    locale: action.locale,
                    messages: getMessage(action.locale)
                }
            );

        default:
            return state;
    }
}

function getMessage(locale) {
    let messages = null;

    switch (locale) {
        case 'id': 
            messages = intlMessagesID; 
            break;
        case 'en': 
            messages = intlMessagesEN; 
            break;
        default: 
            messages = intlMessagesID; 
            break;
    }

    return messages;
}
