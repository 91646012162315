import API from "../../utils/api";

export const getRequest = async () => {
  try {
    const response = await API.get(`api/v1/payment_requests/`);

    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};

export const submitRequest = async payload => {
  try {
    const response = await API.post(`api/v1/payment_requests/`, payload);

    const json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (err) {
    throw err;
  }
};
