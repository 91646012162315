export const errors = {
  "1006": "already_have_pending_request",
  "1007": "minimum_payout_not_met",
  "2200": "incorrect_credentials",
  "2202": "account_not_active",
  "2204": "email_not_yet_registered",
  "2207": "youtube_subscriber_low",
  "2201": "not_authenticated",
  "2500": "invalid_referral_code",
  "2503": "self_referral_error"
};
