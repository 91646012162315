import auth from "../modules/auth/routes";
import dashboard from "../modules/dashboard/routes";
import campaign from "../modules/campaign/routes";
import post from "../modules/post/routes";
import profile from "../modules/profile/routes";
import report from "../modules/report/routes";
import payment from "../modules/payment/routes";
import referral from "../modules/referral/routes";
import deeplink from "../modules/deeplink/routes";

export default [
  ...auth,
  ...dashboard,
  ...campaign,
  ...post,
  ...profile,
  ...report,
  ...payment,
  ...referral,
  ...deeplink
];
