import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { actionTypes as profileActionTypes } from "./reducer";
import { actionTypes as authActionTypes } from "../auth/reducer";
import {
  whoami as serviceGetProfile,
  updateProfile as serviceUpdateProfile,
  updateInterests as serviceUpdateInterests,
  onboardingRequest,
  getUserChannel as serviceGetUserChannel,
  getConnectedBusiness as serviceGetConnectedBusiness,
  createUserChannel as serviceCreateUserChannel
} from "./service";
import { submitReferral } from "../referral/service";
import { login as loginService } from "../auth/service";
import { message } from "antd";
import { errors as errorsList } from "../../utils/errors";

function* getProfile() {
  try {
    const profile = yield call(serviceGetProfile);

    yield put({
      type: profileActionTypes.GET_PROFILE_REQUEST_SUCCESS,
      profile
    });
  } catch (err) {
    const error = errorsList[err.error_code];

    // yield put({
    //   type: authActionTypes.AUTH_LOGOUT,
    //   error
    // });

    // message.error("Session expired, please authenticate again.");
  }
}

function* updateProfile(action) {
  const { payload } = action;

  try {
    const updatedProfile = yield call(serviceUpdateProfile, payload);
    yield put({
      type: profileActionTypes.UPDATE_PROFILE_REQUEST_SUCCESS,
      updatedProfile
    });
    yield put({
      type: profileActionTypes.GET_PROFILE_REQUEST_SUCCESS,
      profile: updatedProfile
    });

    message.success("Successfully updated profile");
  } catch (err) {
    const errors = err.message || err;
    yield put({
      type: profileActionTypes.UPDATE_PROFILE_REQUEST_FAILED,
      errors
    });
    message.error("Failed to update profile");
  }
}

function* updateInterests(action) {
  const { payload } = action;

  try {
    const profile = yield call(serviceUpdateInterests, payload);

    yield put({
      type: profileActionTypes.UPDATE_INTERESTS_REQUEST_SUCCESS,
      profile
    });
    message.success("Successfully updated profile");
  } catch (err) {
    const errors = err.message || err;
    yield put({
      type: profileActionTypes.UPDATE_INTERESTS_REQUEST_FAILED,
      errors
    });
    message.error("Failed to update profile");
  }
}

function* onboardingUser(action) {
  const { payload } = action;

  try {
    const profile = yield call(onboardingRequest, payload);

    let referral = null;
    if (payload.referral_code) {
      referral = yield call(submitReferral, {
        code: payload.referral_code
      });
    }

    yield put({
      type: profileActionTypes.ONBOARDING_REQUEST_SUCCESS,
      profile,
      referral: referral !== null ? referral.referral : null
    });
    message.success("Successfully completed the onboarding");
  } catch (err) {
    const profile = yield call(onboardingRequest, payload);
    const errors = err.message || err;

    switch (err.error_code) {
      case 2500:
        message.error("Referral code not found");

        yield put({
          type: profileActionTypes.ONBOARDING_REQUEST_SUCCESS,
          profile,
          referral: null
        });

        break;

      case 2503:
        message.error("You are not allowed to refer yourself");

        yield put({
          type: profileActionTypes.ONBOARDING_REQUEST_SUCCESS,
          profile,
          referral: null
        });

        break;

      case 2504:
        message.error("Referral is currently not available in your country");

        yield put({
          type: profileActionTypes.ONBOARDING_REQUEST_SUCCESS,
          profile,
          referral: null
        });

        break;

      case 2505:
        yield put({
          type: profileActionTypes.ONBOARDING_REQUEST_SUCCESS,
          profile,
          referral: null
        });

        break;

      case 2201:
        // yield put({
        //   type: authActionTypes.AUTH_LOGOUT,
        //   errors
        // });

        break;
      default:
        message.error("Failed to update profile");
        break;
    }

    yield put({
      type: profileActionTypes.ONBOARDING_REQUEST_FAILED,
      errors
    });
  }
}

function* connect(action) {
  const { payload } = action;

  try {
    yield call(loginService, payload);

    yield put({
      type: profileActionTypes.CONNECT_SOCIAL_MEDIA_REQUEST_SUCCESS
    });

    const profile = yield call(serviceGetProfile);
    yield put({
      type: profileActionTypes.GET_PROFILE_REQUEST_SUCCESS,
      profile
    });
  } catch (err) {
    let errors = errorsList[err.error_code];

    yield put({
      type: profileActionTypes.CONNECT_SOCIAL_MEDIA_REQUEST_FAILED,
      errors
    });
  }
}

function* getUserChannel() {
  try {
    const userChannel = yield call(serviceGetUserChannel);
    yield put({
      type: profileActionTypes.GET_USER_CHANNELS_SUCCESS,
      userChannel
    });
  } catch (err) {
    const errors = errorsList[err.error_code];
    yield put({
      type: profileActionTypes.GET_USER_CHANNELS_FAILED,
      errors
    });
  }
}

function* getConnectedBusiness() {
  try {
    const connectedBusiness = yield call(serviceGetConnectedBusiness);
    yield put({
      type: profileActionTypes.GET_CONNECTED_BUSINESS_SUCCESS,
      connectedBusiness
    });
  } catch (err) {
    const errors = errorsList[err.error_code];
    yield put({
      type: profileActionTypes.GET_CONNECTED_BUSINESS_FAILED,
      errors
    });
  }
}

function* createUserChannel(action) {
  const { payload } = action;
  try {
    for (let data of payload) {
      const userChannel = yield call(serviceCreateUserChannel, data);
      yield put({
        type: profileActionTypes.CREATE_USER_CHANNEL_SUCCESS,
        userChannel
      });
    }

    message.success("Successfully Added!");
  } catch (err) {
    let errors = errorsList[err.error_code];

    yield put({
      type: profileActionTypes.CREATE_USER_CHANNEL_FAILED,
      errors
    });
  }
}

function* watchGetProfileRequest() {
  yield takeLatest(profileActionTypes.GET_PROFILE_REQUEST, getProfile);
}

function* watchUpdateProfileRequest() {
  yield takeEvery(profileActionTypes.UPDATE_PROFILE_REQUEST, updateProfile);
}

function* watchUpdateInterests() {
  yield takeEvery(profileActionTypes.UPDATE_INTERESTS_REQUEST, updateInterests);
}

function* watchOnboarding() {
  yield takeEvery(profileActionTypes.ONBOARDING_REQUEST, onboardingUser);
}

function* watchConnect() {
  yield takeEvery(profileActionTypes.CONNECT_SOCIAL_MEDIA_REQUEST, connect);
}

function* watchGetBusinessAccount() {
  yield takeEvery(profileActionTypes.GET_USER_CHANNELS_REQUEST, getUserChannel);
}

function* watchGetConnectedBusinessAccount() {
  yield takeEvery(
    profileActionTypes.GET_CONNECTED_BUSINESS_REQUEST,
    getConnectedBusiness
  );
}

function* watchCreateUserChannel() {
  yield takeEvery(
    profileActionTypes.CREATE_USER_CHANNEL_REQUEST,
    createUserChannel
  );
}

export default [
  watchGetProfileRequest(),
  watchUpdateProfileRequest(),
  watchUpdateInterests(),
  watchConnect(),
  watchOnboarding(),
  watchGetBusinessAccount(),
  watchGetConnectedBusinessAccount(),
  watchCreateUserChannel()
];
