import Loadable from "react-loadable";
import LoadingComponent from "../../common/loader";

export default [
  {
    path: "/",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/login"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/onboarding",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/onboarding"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/thank-you",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/thank-you"),
      loading: LoadingComponent,
    }),
  },
  // {
  //     path: '/register',
  //     exact: true,
  //     component: Loadable({
  //         loader: () => import('./pages/register'),
  //         loading: LoadingComponent,
  //     }),
  // },
  {
    path: "/forgot-password",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/forgot-password"),
      loading: LoadingComponent,
    }),
  },
];
