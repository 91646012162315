// import { setToken, getToken, deleteToken } from '../../utils/token'

// Action Types
export const actionTypes = {
  GET_POSTS_REQUEST: "GET_POSTS_REQUEST",
  GET_POSTS_REQUEST_SUCCESS: "GET_POSTS_REQUEST_SUCCESS",
  GET_POSTS_REQUEST_FAILED: "GET_POSTS_REQUEST_FAILED",
  CREATE_POST_REQUEST: "CREATE_POST_REQUEST",
  CREATE_POST_REQUEST_SUCCESS: "CREATE_POST_REQUEST_SUCCESS",
  CREATE_POST_REQUEST_FAILED: "CREATE_POST_REQUEST_FAILED",
  UPDATE_POST_REQUEST: "UPDATE_POST_REQUEST",
  UPDATE_POST_REQUEST_SUCCESS: "UPDATE_POST_REQUEST_SUCCESS",
  UPDATE_POST_REQUEST_FAILED: "UPDATE_POST_REQUEST_FAILED",
  UPDATE_REVISE_POST_REQUEST: "UPDATE_REVISE_POST_REQUEST",
  UPDATE_REVISE_POST_REQUEST_SUCCESS: "UPDATE_REVISE_POST_REQUEST_SUCCESS",
  UPDATE_REVISE_POST_REQUEST_FAILED: "UPDATE_REVISE_POST_REQUEST_FAILED",

  UPLOAD_REQUEST: "UPLOAD_REQUEST",
  UPLOAD_REQUEST_SUCCESS: "UPLOAD_REQUEST_SUCCESS",
  UPLOAD_REQUEST_FAILED: "UPLOAD_REQUEST_FAILED"
};

// Reducer
const initialState = {
  posts: {
    results: []
  },
  isLoading: false,
  error: null,
  uploaded: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_REQUEST:
    case actionTypes.UPDATE_REVISE_POST_REQUEST:
    case actionTypes.UPDATE_POST_REQUEST:
    case actionTypes.CREATE_POST_REQUEST:
    case actionTypes.GET_POSTS_REQUEST:
      return Object.assign({}, state, { isLoading: true, error: null });

    case actionTypes.GET_POSTS_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        posts: action.posts
      });

    case actionTypes.CREATE_POST_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null
      });

    case actionTypes.UPLOAD_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        uploaded: state.uploaded
      });

    case actionTypes.UPDATE_REVISE_POST_REQUEST_SUCCESS:
    case actionTypes.UPDATE_POST_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        posts: {
          results: state.posts.results.map(item => {
            if (item.id === action.updatedPost.id) {
              return action.updatedPost;
            } else {
              return item;
            }
          })
        }
      });

    case actionTypes.UPLOAD_REQUEST_FAILED:
    case actionTypes.CREATE_POST_REQUEST_FAILED:
    case actionTypes.UPDATE_POST_REQUEST_FAILED:
    case actionTypes.UPDATE_REVISE_POST_REQUEST_FAILED:
    case actionTypes.GET_POSTS_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        posts: []
      });

    default:
      return state;
  }
};

export const actionCreators = {
  getPostsRequest: filters => ({
    type: actionTypes.GET_POSTS_REQUEST,
    filters
  }),
  createPostRequest: (campaignId, media, post) => ({
    type: actionTypes.CREATE_POST_REQUEST,
    campaignId,
    media,
    post
  }),
  updatePostRequest: (postId, post) => ({
    type: actionTypes.UPDATE_POST_REQUEST,
    postId,
    post
  }),
  updateRevisePostRequest: (postId, post, imagePayload) => ({
    type: actionTypes.UPDATE_REVISE_POST_REQUEST,
    postId,
    post,
    imagePayload
  }),
  uploadMedia: imagePayload => ({
    type: actionTypes.UPLOAD_REQUEST,
    imagePayload
  })
};
