import Loadable from "react-loadable";
import LoadingComponent from "../../common/loader";

export default [
  {
    path: "/deep-link",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages"),
      loading: LoadingComponent
    })
  }
];
